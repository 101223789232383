exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-acquiring-index-tsx": () => import("./../../../src/pages/acquiring/index.tsx" /* webpackChunkName: "component---src-pages-acquiring-index-tsx" */),
  "component---src-pages-acquiring-services-index-tsx": () => import("./../../../src/pages/acquiring/services/index.tsx" /* webpackChunkName: "component---src-pages-acquiring-services-index-tsx" */),
  "component---src-pages-acquiring-services-payment-index-tsx": () => import("./../../../src/pages/acquiring/services/payment/index.tsx" /* webpackChunkName: "component---src-pages-acquiring-services-payment-index-tsx" */),
  "component---src-pages-acquiring-services-payment-qr-index-tsx": () => import("./../../../src/pages/acquiring/services/payment/qr/index.tsx" /* webpackChunkName: "component---src-pages-acquiring-services-payment-qr-index-tsx" */),
  "component---src-pages-acquiring-services-payment-status-index-tsx": () => import("./../../../src/pages/acquiring/services/payment/status/index.tsx" /* webpackChunkName: "component---src-pages-acquiring-services-payment-status-index-tsx" */),
  "component---src-pages-admin-accounts-account-details-index-tsx": () => import("./../../../src/pages/admin/accounts/account-details/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-account-details-index-tsx" */),
  "component---src-pages-admin-accounts-balances-index-tsx": () => import("./../../../src/pages/admin/accounts-balances/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-balances-index-tsx" */),
  "component---src-pages-admin-accounts-create-account-index-tsx": () => import("./../../../src/pages/admin/accounts/create-account/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-create-account-index-tsx" */),
  "component---src-pages-admin-accounts-edit-account-index-tsx": () => import("./../../../src/pages/admin/accounts/edit-account/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-edit-account-index-tsx" */),
  "component---src-pages-admin-accounts-index-tsx": () => import("./../../../src/pages/admin/accounts/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-index-tsx" */),
  "component---src-pages-admin-accounts-permission-index-tsx": () => import("./../../../src/pages/admin/accounts/permission/index.tsx" /* webpackChunkName: "component---src-pages-admin-accounts-permission-index-tsx" */),
  "component---src-pages-admin-cash-collections-index-tsx": () => import("./../../../src/pages/admin/cash-collections/index.tsx" /* webpackChunkName: "component---src-pages-admin-cash-collections-index-tsx" */),
  "component---src-pages-admin-dashboard-index-tsx": () => import("./../../../src/pages/admin/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-admin-dashboard-index-tsx" */),
  "component---src-pages-admin-items-create-item-index-tsx": () => import("./../../../src/pages/admin/items/create-item/index.tsx" /* webpackChunkName: "component---src-pages-admin-items-create-item-index-tsx" */),
  "component---src-pages-admin-items-index-tsx": () => import("./../../../src/pages/admin/items/index.tsx" /* webpackChunkName: "component---src-pages-admin-items-index-tsx" */),
  "component---src-pages-admin-limits-create-limit-index-tsx": () => import("./../../../src/pages/admin/limits/create-limit/index.tsx" /* webpackChunkName: "component---src-pages-admin-limits-create-limit-index-tsx" */),
  "component---src-pages-admin-limits-index-tsx": () => import("./../../../src/pages/admin/limits/index.tsx" /* webpackChunkName: "component---src-pages-admin-limits-index-tsx" */),
  "component---src-pages-admin-limits-update-limit-index-tsx": () => import("./../../../src/pages/admin/limits/update-limit/index.tsx" /* webpackChunkName: "component---src-pages-admin-limits-update-limit-index-tsx" */),
  "component---src-pages-admin-list-accounts-index-tsx": () => import("./../../../src/pages/admin/list-accounts/index.tsx" /* webpackChunkName: "component---src-pages-admin-list-accounts-index-tsx" */),
  "component---src-pages-admin-service-distribution-fees-index-tsx": () => import("./../../../src/pages/admin/service-distribution-fees/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-distribution-fees-index-tsx" */),
  "component---src-pages-admin-service-distribution-fees-update-service-distribution-fees-index-tsx": () => import("./../../../src/pages/admin/service-distribution-fees/update-service-distribution-fees/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-distribution-fees-update-service-distribution-fees-index-tsx" */),
  "component---src-pages-admin-service-fees-index-tsx": () => import("./../../../src/pages/admin/service-fees/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-fees-index-tsx" */),
  "component---src-pages-admin-service-fees-update-service-fees-index-tsx": () => import("./../../../src/pages/admin/service-fees/update-service-fees/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-fees-update-service-fees-index-tsx" */),
  "component---src-pages-admin-service-payments-index-tsx": () => import("./../../../src/pages/admin/service-payments/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-payments-index-tsx" */),
  "component---src-pages-admin-service-payments-payment-index-tsx": () => import("./../../../src/pages/admin/service-payments/payment/index.tsx" /* webpackChunkName: "component---src-pages-admin-service-payments-payment-index-tsx" */),
  "component---src-pages-admin-services-create-category-index-tsx": () => import("./../../../src/pages/admin/services/create-category/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-create-category-index-tsx" */),
  "component---src-pages-admin-services-create-service-endpoint-index-tsx": () => import("./../../../src/pages/admin/services/create-service-endpoint/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-create-service-endpoint-index-tsx" */),
  "component---src-pages-admin-services-create-service-index-tsx": () => import("./../../../src/pages/admin/services/create-service/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-create-service-index-tsx" */),
  "component---src-pages-admin-services-distribution-fee-index-tsx": () => import("./../../../src/pages/admin/services/distribution-fee/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-distribution-fee-index-tsx" */),
  "component---src-pages-admin-services-index-tsx": () => import("./../../../src/pages/admin/services/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-index-tsx" */),
  "component---src-pages-admin-services-services-fees-index-tsx": () => import("./../../../src/pages/admin/services/services-fees/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-services-fees-index-tsx" */),
  "component---src-pages-admin-services-update-category-index-tsx": () => import("./../../../src/pages/admin/services/update-category/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-update-category-index-tsx" */),
  "component---src-pages-admin-services-update-service-index-tsx": () => import("./../../../src/pages/admin/services/update-service/index.tsx" /* webpackChunkName: "component---src-pages-admin-services-update-service-index-tsx" */),
  "component---src-pages-admin-terminals-index-tsx": () => import("./../../../src/pages/admin/terminals/index.tsx" /* webpackChunkName: "component---src-pages-admin-terminals-index-tsx" */),
  "component---src-pages-admin-terminals-update-terminal-index-tsx": () => import("./../../../src/pages/admin/terminals/update-terminal/index.tsx" /* webpackChunkName: "component---src-pages-admin-terminals-update-terminal-index-tsx" */),
  "component---src-pages-admin-transactions-index-tsx": () => import("./../../../src/pages/admin/transactions/index.tsx" /* webpackChunkName: "component---src-pages-admin-transactions-index-tsx" */),
  "component---src-pages-admin-users-create-account-index-tsx": () => import("./../../../src/pages/admin/users/create-account/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-create-account-index-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-user-create-index-tsx": () => import("./../../../src/pages/admin/users/user-create/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-create-index-tsx" */),
  "component---src-pages-admin-users-user-details-accounts-index-tsx": () => import("./../../../src/pages/admin/users/user-details-accounts/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-details-accounts-index-tsx" */),
  "component---src-pages-admin-users-user-details-form-index-tsx": () => import("./../../../src/pages/admin/users/user-details-form/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-details-form-index-tsx" */),
  "component---src-pages-admin-users-user-details-index-tsx": () => import("./../../../src/pages/admin/users/user-details/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-user-details-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-data-processing-policy-index-tsx": () => import("./../../../src/pages/data-processing-policy/index.tsx" /* webpackChunkName: "component---src-pages-data-processing-policy-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-verify-code-index-tsx": () => import("./../../../src/pages/login/verify-code/index.tsx" /* webpackChunkName: "component---src-pages-login-verify-code-index-tsx" */),
  "component---src-pages-oauth-2-authorize-index-tsx": () => import("./../../../src/pages/oauth2/authorize/index.tsx" /* webpackChunkName: "component---src-pages-oauth-2-authorize-index-tsx" */),
  "component---src-pages-oauth-2-authorize-verify-code-index-tsx": () => import("./../../../src/pages/oauth2/authorize/verify-code/index.tsx" /* webpackChunkName: "component---src-pages-oauth-2-authorize-verify-code-index-tsx" */),
  "component---src-pages-oauth-2-client-index-tsx": () => import("./../../../src/pages/oauth2/client/index.tsx" /* webpackChunkName: "component---src-pages-oauth-2-client-index-tsx" */),
  "component---src-pages-terminals-index-tsx": () => import("./../../../src/pages/terminals/index.tsx" /* webpackChunkName: "component---src-pages-terminals-index-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */),
  "component---src-pages-terms-of-use-mobile-index-tsx": () => import("./../../../src/pages/terms-of-use-mobile/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-mobile-index-tsx" */),
  "component---src-pages-test-index-jsx": () => import("./../../../src/pages/test/index.jsx" /* webpackChunkName: "component---src-pages-test-index-jsx" */)
}

