// External dependencies
import { gql } from '@apollo/client';

export const listAccountsBalanceQuery = gql`
  query listAccountsBalance($input: ListAccountsBalanceInput!) {
    listAccountsBalance(input: $input) {
      accountsBalances {
        createdDate
        amount
      }
      total
    }
  }
`;
