// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../clients/averspay';
import { listAccountsBalanceError, listAccountsBalanceSuccess } from './actions';
import { listAccountsBalanceQuery } from './queries';
import {
  AccountsBalanceInput,
  ListAccountsBalance,
  ListAccountsBalanceAction,
  ListAccountsBalanceActionTypes,
  ListAccountsBalanceSuccess,
} from './types';

export function listAccountsBalancesEpic(action$) {
  return action$.pipe(
    filter((action: ListAccountsBalanceAction) => action.type === ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE),
    switchMap((action: ListAccountsBalance) =>
      listAccountsBalance(action.input).catch((error) => listAccountsBalanceError(error)),
    ),
  );
}

export async function listAccountsBalance(input: AccountsBalanceInput): Promise<ListAccountsBalanceSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { listAccountsBalance },
  } = await graphQLClient.query({
    query: listAccountsBalanceQuery,
    variables: { input },
  });

  return listAccountsBalanceSuccess(listAccountsBalance);
}
