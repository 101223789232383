export const DEFAULT_PATH = '/';
export const LOGIN_PATH = '/login';

export const DEFAULT_PATH_FOR_ADMIN = '/admin/dashboard';
export const DEFAULT_PATH_FOR_ACCOUNTANT = '/admin/dashboard';
export const DEFAULT_PATH_FOR_DEALER = '/admin/transactions';
export const DEFAULT_PATH_FOR_MANAGER = '/admin/services';
export const DEFAULT_PATH_FOR_OPERATOR = '/admin/transactions';

export const USERS_PATH_FOR_ACCOUNTANT = '/admin/users';

export const DEFAULT_PATH_USER = '/admin/transactions';
export const DEFAULT_PATH_CASHIER = '/admin/cash-collections';
export const DEFAULT_PATH_TECHNICIAN = '/admin/accounts';

export const USERS_PATH_FOR_MANAGER = '/admin/users';
export const TRANSACTIONS_PATH_FOR_MANAGER = '/admin/transactions';
export const TERMINALS_PATH_FOR_MANAGER = '/admin/terminals';
export const SERVICE_FEE_PATH_FOR_MANAGER = '/admin/service-fees';
export const DISTRIBUTION_FEE_PATH_FOR_MANAGER = '/admin/service-distribution-fees';
export const DASHBOARD_PATH = '/admin/dashboard';
export const LIMITS_PATH = '/admin/limits';
export const ACCOUNTS_PATH = '/admin/accounts';
export const SERVICE_PAYMENTS_PATH = '/admin/service-payments';
export const CASH_COLLECTIONS_PATH_FOR_ACCOUNTANT = '/admin/cash-collections';
export const FINIK_PATH = '/admin/items';
export const ACCOUNTS_BALANCES_PATH = '/admin/accounts-balances';

export function getUserDefaultPath() {
  return DEFAULT_PATH_USER;
}

export function getAdminDefaultPath() {
  return DEFAULT_PATH_FOR_ACCOUNTANT;
}

export function getAccountantDefaultPath() {
  return DEFAULT_PATH_FOR_ACCOUNTANT;
}

export function getDealerDefaultPath() {
  return DEFAULT_PATH_FOR_DEALER;
}

export function getManagerDefaultPath() {
  return DEFAULT_PATH_FOR_MANAGER;
}

export function getOperatorDefaultPath() {
  return DEFAULT_PATH_FOR_OPERATOR;
}

export function getComplianceManagerDefaultPath() {
  return USERS_PATH_FOR_MANAGER;
}

export function getCashierDefaultPath() {
  return DEFAULT_PATH_CASHIER;
}

export function getTechnicianDefaultPath() {
  return DEFAULT_PATH_TECHNICIAN;
}
