// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { ListAccountsBalanceAction, ListAccountsBalanceActionTypes, ListAccountsBalanceState } from './types';

const initialState: ListAccountsBalanceState = {
  accountsBalances: [],
  loading: false,
  error: null,
  total: 0,
  from: 0,
  size: 20,
};

export default function accountsBalancesReducer(
  state: ListAccountsBalanceState = initialState,
  action: ListAccountsBalanceAction,
): ListAccountsBalanceState {
  switch (action.type) {
    case ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE:
      return update(state, {
        loading: { $set: true },
        from: { $set: action.input.from },
        size: { $set: action.input.size },
      });

    case ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        accountsBalances: { $set: action.accountsBalances },
        total: { $set: action.total },
      });

    case ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    default:
      return state;
  }
}
