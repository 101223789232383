export enum ListAccountsBalanceActionTypes {
  LIST_ACCOUNTS_BALANCE = 'LIST_ACCOUNTS_BALANCE',
  LIST_ACCOUNTS_BALANCE_SUCCESS = 'LIST_ACCOUNTS_BALANCE_SUCCESS',
  LIST_ACCOUNTS_BALANCE_ERROR = 'LIST_ACCOUNTS_BALANCE_ERROR',
}

export interface AccountsBalance {
  createdDate: string;
  amount: number;
}

export interface AccountsBalanceInput {
  from: number;
  size: number;
}

export interface ListAccountsBalanceState {
  accountsBalances: AccountsBalance[];
  loading: boolean;
  error: Error | null;
  total: number;
  from: number;
  size: number;
}

export interface ListAccountsBalanceResponse {
  accountsBalances: AccountsBalance[];
  total: number;
}

export interface ListAccountsBalance {
  type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE;
  input: AccountsBalanceInput;
}

export interface ListAccountsBalanceSuccess {
  type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_SUCCESS;
  accountsBalances: AccountsBalance[];
  total: number;
}

export interface ListAccountsBalanceError {
  type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_ERROR;
  error: Error;
}

export type ListAccountsBalanceAction = ListAccountsBalance | ListAccountsBalanceSuccess | ListAccountsBalanceError;
