// Local dependencies
import {
  AccountsBalanceInput,
  ListAccountsBalance,
  ListAccountsBalanceActionTypes,
  ListAccountsBalanceError,
  ListAccountsBalanceResponse,
  ListAccountsBalanceSuccess,
} from './types';

export function listAccountsBalance(input: AccountsBalanceInput): ListAccountsBalance {
  return {
    type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE,
    input,
  };
}

export function listAccountsBalanceSuccess({
  accountsBalances,
  total,
}: ListAccountsBalanceResponse): ListAccountsBalanceSuccess {
  return {
    type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_SUCCESS,
    accountsBalances,
    total,
  };
}

export function listAccountsBalanceError(error: Error): ListAccountsBalanceError {
  return {
    type: ListAccountsBalanceActionTypes.LIST_ACCOUNTS_BALANCE_ERROR,
    error,
  };
}
