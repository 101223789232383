import { UserRole } from '../../helpers/helpers';
import * as roles from '../components/common/roles';

export const pagePermissions = {
  all: [
    UserRole.ACCOUNTANT,
    UserRole.ADMIN,
    UserRole.USER,
    UserRole.TECHNICIAN,
    UserRole.AVERSPAY_USER,
    UserRole.DEALER,
    UserRole.MANAGER,
    UserRole.OPERATOR,
    UserRole.COMPLIANCE_MANAGER,
    UserRole.CASHIER,
  ],
  accounts: [UserRole.ACCOUNTANT, UserRole.ADMIN, UserRole.USER, UserRole.TECHNICIAN],
  services: [UserRole.MANAGER, UserRole.OPERATOR, UserRole.ADMIN],
  createService: [UserRole.MANAGER, UserRole.ADMIN],
  updateService: [UserRole.MANAGER, UserRole.ADMIN],
  transactions: [
    UserRole.OPERATOR,
    UserRole.ADMIN,
    UserRole.ACCOUNTANT,
    UserRole.MANAGER,
    UserRole.COMPLIANCE_MANAGER,
    UserRole.USER,
    UserRole.DEALER,
    UserRole.TECHNICIAN,
  ],
  users: [UserRole.MANAGER, UserRole.ADMIN, UserRole.OPERATOR, UserRole.ACCOUNTANT, UserRole.COMPLIANCE_MANAGER],
  userAccounts: [UserRole.ADMIN, UserRole.ACCOUNTANT],
  terminals: [UserRole.OPERATOR, UserRole.MANAGER, UserRole.ADMIN, UserRole.ACCOUNTANT],
  serviceFees: [UserRole.ADMIN, UserRole.MANAGER],
  updateTerminal: [UserRole.MANAGER, UserRole.ADMIN],
  fees: [UserRole.MANAGER, UserRole.ADMIN],
  dashboard: [UserRole.ADMIN, UserRole.ACCOUNTANT],
  limits: [UserRole.ADMIN],
  cashCollection: [UserRole.ADMIN, UserRole.ACCOUNTANT, UserRole.CASHIER],
  createItem: [
    UserRole.ADMIN,
    UserRole.MANAGER,
    UserRole.ACCOUNTANT,
    UserRole.OPERATOR,
    UserRole.DEALER,
    UserRole.USER,
    UserRole.CASHIER,
    UserRole.COMPLIANCE_MANAGER,
    UserRole.TECHNICIAN,
  ],
  createUser: [UserRole.ADMIN, UserRole.MANAGER],
  finiks: [
    UserRole.ADMIN,
    UserRole.MANAGER,
    UserRole.TECHNICIAN,
    UserRole.ACCOUNTANT,
    UserRole.OPERATOR,
    UserRole.DEALER,
    UserRole.USER,
    UserRole.CASHIER,
    UserRole.COMPLIANCE_MANAGER,
  ],
  permission: [
    UserRole.ADMIN,
    UserRole.MANAGER,
    UserRole.ACCOUNTANT,
    UserRole.OPERATOR,
    UserRole.DEALER,
    UserRole.USER,
    UserRole.CASHIER,
    UserRole.COMPLIANCE_MANAGER,
  ],
  accountsBalances: [UserRole.ADMIN, UserRole.ACCOUNTANT],
};

export const hasAccess = (rolesToCheck: roles.UserRole[], userRole: roles.UserRole) => rolesToCheck.includes(userRole);
